import React from 'react';
import '../../styles/app.global.scss';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductPageAppProps} from '../../types/app-types';
import {ClassicLayout} from '../Layouts/ClassicLayout/ClassicLayout';
import {QuickViewLayout} from '../Layouts/QuickViewLayout/QuickViewLayout';
import {MobileLayout} from '../Layouts/MobileLayout/MobileLayout';
import {SimpleLayout} from '../Layouts/SimpleLayout/SimpleLayout';
import {SleekLayout} from '../Layouts/SleekLayout/SleekLayout';
import {SpotlightLayout} from '../Layouts/SpotlightLayout/SpotlightLayout';
import {StunningLayout} from '../Layouts/StunningLayout/StunningLayout';
import {ResponsiveLayout} from '../Layouts/ResponsiveLayout/ResponsiveLayout';
import {LayoutId, LayoutNames} from '../../constants';
import s from './ProductPageApp.scss';
import {getLayoutIdFromProps, getStyleParamsFromProps} from '../../commons/styleParamsService';
import cx from 'classnames';

const layouts = {
  [LayoutId.Classic]: ClassicLayout,
  [LayoutId.Mobile]: MobileLayout,
  [LayoutId.QuickView]: QuickViewLayout,
  [LayoutId.Simple]: SimpleLayout,
  [LayoutId.Sleek]: SleekLayout,
  [LayoutId.Spotlight]: SpotlightLayout,
  [LayoutId.Stunning]: StunningLayout,
  [LayoutId.Responsive]: ResponsiveLayout,
};

export const LayoutContext = React.createContext('');

@withGlobalProps
export class ProductPageApp extends React.Component<ProductPageAppProps> {
  constructor(props) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  private renderEmpty() {
    return (
      <h1 data-hook="empty-state" className={s.emptyState}>
        Product Not Found
      </h1>
    );
  }

  private renderProduct() {
    const {product, isDesktop, isQuickView} = this.props.globals;
    const layoutId: LayoutId = isQuickView ? LayoutId.QuickView : getLayoutIdFromProps(this.props);
    const settings = getStyleParamsFromProps(this.props);
    const LayoutComp = layouts[layoutId];
    const layoutName = LayoutNames[layoutId];
    const isMobile = layoutName === LayoutNames[LayoutId.Mobile];
    const classNames = cx(s.ProductPageApp, {
      isMobile,
      [s.mobile]: isMobile,
      isDesktop,
    });

    return (
      <LayoutContext.Provider value={layoutName}>
        <div data-hook="product-page" className={classNames} data-layout-name={layoutName}>
          <LayoutComp
            data-hook="product-layout"
            product={product}
            settings={settings}
            globals={this.props.globals}
            {...this.props}
          />
        </div>
      </LayoutContext.Provider>
    );
  }

  public componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
    this.safeRun(this.props.notifyProduct);
  }

  public componentDidUpdate(prevProps: Readonly<ProductPageAppProps>): void {
    if (
      !this.props.emptyState &&
      (prevProps.globals.product.id !== this.props.globals.product.id ||
        prevProps.globals.isInteractive !== this.props.globals.isInteractive)
    ) {
      this.safeRun(this.props.notifyProduct);
    }
  }

  private reportAppLoaded() {
    this.safeRun(this.props.globals.onAppLoaded);
  }

  private safeRun(fn) {
    if (this.props.globals.isInteractive && typeof fn === 'function') {
      fn();
    }
  }

  public render() {
    const {emptyState} = this.props;
    return emptyState ? this.renderEmpty() : this.renderProduct();
  }
}
